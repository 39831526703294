import React, { Component } from "react";
import { StyleSheet, Text, View, TextInput, Image } from "react-native";
import { Fonts, Colors, Images } from "../themes";
import { Trash } from "lucide-react";
import { Button } from "./ui/button";
class Input extends Component {
  static defaultProps = {
    title: "",
    placeholder: "",
    style: {},
    status: null,
    onChangeValue: () => {},
    onDestroy: null,
    upper: false,
    multiline: false,
    lower: false,
    maxLength: -1,
    icon: "",
    type: "none",
    inputHeight: 45,
    textHeight: null,
  };

  constructor(props) {
    super(props);

    this.state = {
      value: "",
      focus: false,
    };
    this.initial = "";
  }

  componentDidUpdate() {
    if (this.props.initial !== this.initial && this.props.initial) {
      this.initial = this.props.initial;
      var valor = this.props.initial;
      if (this.props.type === "money") {
        if (typeof valor === "number") {
          valor = valor.toFixed(2);
        }
        valor = valor.replace(/\D/g, "");
        const valorChanged = parseInt(valor) / 100.0;
        valor = valorChanged.toFixed(2);
        valor = `R$ ${this.formatMoney(valor.replace(",", "."), 2, ",", ".")}`;
      }
      this.setState({ value: valor });
    }
  }

  formatMoney(n, c, d, t) {
    c = isNaN((c = Math.abs(c))) ? 2 : c;
    d = d === undefined ? "." : d;
    t = t === undefined ? "," : t;
    let s = n < 0 ? "-" : "";
    let i = String(parseInt((n = Math.abs(Number(n) || 0).toFixed(c))));
    let j = i.length;
    j = j > 3 ? j % 3 : 0;

    return (
      s +
      (j ? i.substr(0, j) + t : "") +
      i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + t) +
      (c
        ? d +
          Math.abs(n - i)
            .toFixed(c)
            .slice(2)
        : "")
    );
  }

  onChange(text) {
    var valor = text;
    if (this.props.lower) {
      valor = valor.toLowerCase();
    }
    if (this.props.maxLength > -1) {
      valor = text.substring(0, this.props.maxLength);
    }
    if (this.props.upper) {
      valor = valor.toUpperCase();
    }
    if (this.props.type === "money") {
      valor = valor.replace(/\D/g, "");
      const valorChanged = parseInt(valor) / 100.0;
      valor = valorChanged.toFixed(2);
      valor = `R$ ${this.formatMoney(valor.replace(",", "."), 2, ",", ".")}`;
      this.setState({ value: valor });
      if (this.timeout) clearTimeout(this.timeout);
      this.timeout = setTimeout(() => {
        this.props.onChangeValue(valorChanged);
      }, 500);
    } else {
      if (this.props.type === "number") {
        valor = valor.replace(/\D/g, "");
      }
      this.setState({ value: valor });
      if (this.timeout) clearTimeout(this.timeout);
      this.timeout = setTimeout(() => {
        this.props.onChangeValue(valor);
      }, 500);
    }
  }

  getPaddingLeft() {
    if (this.props.icon === "search") {
      return 40;
    }

    return 10;
  }

  renderIcon() {
    if (this.props.icon === "search") {
      return (
        <Image
          source={{ uri: Images.search }}
          style={{
            width: 20,
            height: 20,
            position: "absolute",
            left: 10,
            top: 13,
          }}
        />
      );
    }
  }

  renderStatus() {
    if (this.props.status === "checked") {
      return (
        <Image
          source={{ uri: Images.input_checked }}
          style={styles.hintImage}
        />
      );
    }
    if (this.props.status === "warning") {
      return (
        <Image
          source={{ uri: Images.input_warning }}
          style={[styles.hintImage, { height: 20 }]}
        />
      );
    }
  }

  renderDestroy() {
    return (
      <Button
        variant="outline"
        size="icon"
        onClick={() => this.props.onDestroy && this.props.onDestroy()}
      >
        <Trash />
      </Button>
    );
  }

  render() {
    const { title } = this.props;
    return (
      <View style={[styles.container, this.props.style]}>
        <View style={{ flex: 1 }}>
          {this.props.title !== null && this.props.title.length > 0 && (
            <Text style={styles.title}>{title}</Text>
          )}
          <TextInput
            underlineColorAndroid={"transparent"}
            placeholder={this.props.placeholder}
            onFocus={() => this.setState({ focus: true })}
            onBlur={() => this.setState({ focus: false })}
            onChangeText={this.onChange.bind(this)}
            secureTextEntry={
              this.props.secureTextEntry !== undefined
                ? this.props.secureTextEntry
                : false
            }
            value={this.state.value}
            style={[
              styles.inputContainer,
              { height: this.props.inputHeight },
              this.state.focus
                ? { borderColor: Colors.primary, shadowRadius: 5 }
                : {},
              { paddingLeft: this.getPaddingLeft() },
              this.props.multiline
                ? {
                    height: this.props.textHeight || 210,
                    paddingTop: 10,
                  }
                : {},
            ]}
            multiline={this.props.multiline}
          />
        </View>
        {this.renderIcon()}
        {this.renderStatus()}
        {this.props.onDestroy && this.renderDestroy()}
      </View>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    marginTop: 20,
    display: "flex",
    flexDirection: "row",
    gap: 5,
  },
  inputContainer: {
    height: 45,
    borderWidth: 1,
    borderRadius: 3,
    borderColor: Colors.divider,
    paddingLeft: 10,
    paddingRight: 10,
    fontFamily: Fonts.type.regular,
    fontSize: 14,
    color: Colors.text,
    overflow: "hidden",
    // shadowColor: Colors.primary,
    // shadowRadius: 0,
    shadowOpacity: 0,
  },
  title: {
    fontFamily: Fonts.type.medium,
    fontSize: 14,
    color: Colors.text,
    marginBottom: 5,
  },
  hintImage: {
    position: "absolute",
    right: 12,
    bottom: 12,
    width: 20,
    height: 21,
  },
});

export default Input;
