import {
  RESTAURANT_LIST_REQUEST,
  RESTAURANT_LIST_SUCCESS,
  RESTAURANT_LIST_FAIL,
  RESTAURANT_LIST_SET,
  DASHBOARD_SET_VALUE,
  DASHBOARD_CHANGE_FILTER,
} from "../constants";

const initialState = {
  loading: false,
  all: [],
  restaurants: [],
  filter_city: [1],
  filter_active: ["Ativo"],
  // cities: [{ id: 1, name: 'Aracaju' }, { id: 3, name: 'Salvador' }]
};
export const dashboard = (state = initialState, action) => {
  switch (action.type) {
    case DASHBOARD_SET_VALUE: {
      var value = action.value;
      const r = { ...state };
      r[action.key] = value;
      return r;
    }
    case DASHBOARD_CHANGE_FILTER:
      return {
        ...state,
        filter_city: action.filter_city,
        filter_active: action.filter_active,
      };
    case RESTAURANT_LIST_REQUEST:
      return { ...state, loading: true };
    case RESTAURANT_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        restaurants: action.restaurants,
        all: action.restaurants,
      };
    case RESTAURANT_LIST_FAIL:
      return { ...state, loading: false };
    case RESTAURANT_LIST_SET:
      return { ...state, restaurants: action.restaurants };
    default:
      return state;
  }
};
